@import "variables";

html {
  box-sizing: border-box;
  font-family: Tahoma, Geneva, Verdana, sans-serif;
}

//Resetting margin & padding on every element & setting box sizing
*,
*::after,
*:before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

/* #region  Text */
h2 {
  color: $col_Heading;

  //Making sure that the font size scale with screen size. clamp(min, prefered, max)
  font-size: clamp(1rem, calc(1rem + 1vw), 3rem);
}

#secondHeadingWhatWeDo {
  color: $col_SecondHeadingWhatWeDo;
}

#secondHeadingMaintainable {
  color: $col_SecondHeadingMaintainable;
}

#secondHeadingGetInTouch {
  color: $col_SecondHeadingGetInTouch;
}

.mainText {
  white-space: pre-line;
  color: $col_MainText;
  //Making sure that the font size scale with screen size. clamp(min, prefered, max)
  font-size: clamp(12px, calc(.6rem + .8vw), 1rem);
}

//the text boxes on the three subpages
.title,
.titleGetInTouch {
  margin-left: auto;
  background-color: $col_TextBg;
  color: $col_MainText;
  display: block;
  border: 0px;
  outline: none;
  padding-inline: 5px;
  padding-block: 5px;
  font-weight: bold;
  width: 115px;
  text-align: center;
  position: relative;
  z-index: 10;
}

/* #endregion */


//* Home
/* #region  Home */
#home {
  background: $col_Bg_Home;
  min-height: 100vh;

   .container-lg{
    position: relative;
   }
}

//the navigation from the landingpage, with the images
#homeNavigation {
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  ul {
    list-style-type: none;

    li,
    a {
      display: inline-block;
      height: fit-content;
    }

    a {
      text-decoration: none;
    }

  }

}

//top logo
#nuoLogo {
  width: 150px;
}

//the text box below the last image on the home page
#moreToCome {
  background-color: $col_TextBg;
  color: $col_MainText;
  display: block;
  border: 0px;
  outline: none;
  padding-inline: 10px;
  padding-block: 2px;
  font-weight: bold;
}

/* #endregion */

//*Shared styles subpages (min. 2 pages with same style)
/* #region Styles for all subpages  */
.pageImageCol {
  margin-left: 80px;
}

.pageImages {
  max-width: 220px;
  position: relative;
}

.nuoLogoSubPages {
  width: 125px;
  z-index: 1;
}

//*Firefox browser and chrome browser handles height of content diffrently. 
//*Check GetInTouch.jsx, Maintainable.jsx, WhatWeDo.jsx for implementation
/* #region  Firefox / Chrome specific styling */
#maintainableContainer .row .isFirefox,
#whatwedoContainer .row .isFirefox {
  padding-bottom: 0;
  margin-bottom: -.45rem;

}

#maintainableContainer .row .isChrome,
#whatwedoContainer .row .isChrome {
  padding-bottom: 2rem;
}

/* #endregion */

/* #endregion */

//*WhatWeDo
/* #region WhatWeDo  */
#whatwedoContainer {
  background-color: $col_Bg_WhatWeDo;
  min-height: 100vh;
  position: relative;
}

/* #endregion */

//*Maintainable
#maintainableContainer {
  background-color: $col_Bg_Maintainable;
  min-height: 100vh;

  position: relative;

}

//*GetInTouch
/* #region GetInTouch  */
#getInTouchContainer {
  background-color: $col_Bg_GetInTouch;
  min-height: 100vh;

  position: relative;

}

//*Firefox browser and chrome browser handles height of content diffrently. 
//*Check GetInTouch.jsx, Maintainable.jsx, WhatWeDo.jsx for implementation
/* #region  Firefox / Chrome specific styling */
#getInTouchContainer .row .isFirefox {
  padding-bottom: 3rem;
}

#getInTouchContainer .row .isChrome {
  padding-bottom: 4.4rem;
}

/* #endregion */

#getInTouchArticle {
  position: relative;
}

.employeePortrait {
  max-width: 130px;
  position: relative;

  &.enablePointer {
    cursor: pointer;
  }
}


.employeeCaption {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translate(-50%);
  font-size: .8rem;
  font-weight: bold;
  text-align: center;
  color: $col_MainText;
}

/* #endregion */

//*Footer
#mainFooter{
  width: 100%;
  background-color: transparent;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  margin-bottom: 1rem;
}

//*Media query
/* #region  Media queries */
@media only screen and (max-width: 1199px) {

  /* #region  Home */
  #nuoLogo {
    margin-left: 120px;
  }

  /* #endregion */

  //*Styles for all subpages
  .title {
    // margin-top: calc(.6em + 11.5vw);
    margin-top: 148px;
  }

  //*GetInTouch
  .titleGetInTouch {
    margin-top: 164px;
  }

}


@media only screen and (max-width: 991px) {

  /* #region  Home */
  #nuoLogo {
    margin-left: calc(.8em + 12vw);
  }

  /* #endregion */

}

@media only screen and (max-width: 767px) {

  /* #region  Home */
  #nuoLogo {
    margin-left: calc(.6vw + .1em)
  }

  #moreToCome {
    margin-top: 10px;
    width: 49%;
    margin-left: auto;
  }

  .homePage_ImageContainer {
    max-width: 95vw;
  }

  /* #endregion */

  /* #region  Styles for all subpages */
  .pageImageCol {
    margin-left: 0;
  }

  .pageImages {
    max-width: unset;
  }

  /* #endregion */

}

@media only screen and (min-width: 1200px) {

  /* #region  Home */
  #nuoLogo {
    margin-left: 166px;
  }

  /* #endregion */

  //*Styles for all subpages
  .title {
    margin-top: 189px;
  }

  //*GetInTouch
  .titleGetInTouch {
    margin-top: 167px;
  }

}

@media only screen and (min-width: 1400px) {

  /* #region  Home */
  #nuoLogo {
    margin-left: 210px;
  }

  /* #endregion */

  //*Styles for all subpages
  .title {
    // margin-top: 110px;
    margin-top: 228px;

  }

  //*GetInTouch
  .titleGetInTouch {
    margin-top: 171px;
  }

}

/* #endregion */