//*Check scss variables.scss in variables and mixins (functions) on mixins.scss
@import "variables", "mixins";

//* Navigation.js
#navbar{
    margin-bottom: 35px;
    padding-top: 25px;
}


#navlist {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
        width: 70px;
        cursor: pointer;
    }    

    li:not(:nth-child(4)) {
        margin-right: 15px;
    }
}

//Nav link styling
.navlink {
    display: inline-block;
    width: 60px;
    height: 60px;
    margin-block: 15px;
    border-radius: 8px;
    text-decoration: none;
}

.navlink--WhatWeDo {
    background-color: $col_Navlink_WhatWeDo;
    position: relative;

    //*Styling if on WhatWeDo page
    &.active::before {
        //*Mixin
        @include navLinkActiveStyle($col_Navlink_WhatWeDo, false);

    }
}

.navlink--Maintainable {
    background-color: $col_Navlink_Maintainable;
    position: relative;

    //*Styling if on Maintainable page
    &.active::before {
        @include navLinkActiveStyle($col_Navlink_Maintainable, false);

    }
}

.navlink--GetInTouch {
    background-color: $col_Navlink_GetInTouch;
    position: relative;

    //*Styling if on GetInTouch page
    &.active::before {
        @include navLinkActiveStyle($col_Navlink_GetInTouch, false);

    }
}


//*Media query (767px = md breakpoint in bootstrap)
@media only screen and (max-width: 767px) {

    #navbar{
        margin-bottom: 0;
    }

    #navlist{
        img{
            width: 40px
        }

        li:not(:nth-child(4)) {
            margin-right: 10px;
        }
    } 

    .navlink{
        height: 30px;
        width: 30px;
    }

    .navlink--WhatWeDo{
        &.active::before{
            @include navLinkActiveStyle($col_Navlink_WhatWeDo, true)

        }
    }

    .navlink--Maintainable {

        &.active::before {
            @include navLinkActiveStyle($col_Navlink_Maintainable, true);
    
        }
    }

    .navlink--GetInTouch{
        &.active::before{
            @include navLinkActiveStyle($col_Navlink_GetInTouch, true)
        }
    }
    
}